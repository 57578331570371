<template>
	<CModal
		:show.sync="isShow"
		:close-on-backdrop="false"
		class="modal-confirm"
		centered
	>
		<template #header-wrapper>
			<ModalHeaderWrapper
				:title="title"
				@onClose="close"
			/>
		</template>
		<template #default>
			<p>{{ description }}</p>
		</template>
		<template #footer>
			<CButton
				color="tertiary"
				class="mr-3"
				@click="close"
			>
				Cancel
			</CButton>
			<CButton
				:disabled="isSubmitting"
				color="primary"
				@click="handleConfirm"
			>
				{{ isSubmitting ? primaryButtonLoadingText : primaryButtonText }}
			</CButton>
		</template>
	</CModal>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';

export default {
	name: 'BaseModalConfirm',
	components: {
		ModalHeaderWrapper,
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		primaryButtonText: {
			type: String,
			default: 'Confirm',
		},
		primaryButtonLoadingText: {
			type: String,
			default: 'Submitting',
		},
		closeAfterConfirm: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isShow: false,
		};
	},
	methods: {
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
			this.$emit('onClose');
		},
		handleConfirm() {
			this.$emit('onConfirm');

			if (this.closeAfterConfirm) {
				this.close();
			}
		},
	},
};
</script>
