<template>
	<div>
		<CRow class="mb-3">
			<CCol class="text-right">
				<CButton
					class="btn-export"
					color="tertiary"
					data-test-id="btn-export"
					@click="openExportModal"
				>
					Export
				</CButton>
			</CCol>
		</CRow>
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						data-test-id="search-input"
						placeholder="Search by SKU no., Product name, Customer name, Customer email or Customer ID"
					>
						<template #prepend-content>
							<CIcon
								name="cil-magnifying-glass"
								data-test-id="search-button"
							/>
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					class="ml-3"
					data-test-id="filter-button"
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="filter-box bg-gray-100 rounded-sm py-3 px-3 mb-3"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									v-model="dropdown.customer_group"
									:options="customerGroupsOptions"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Customer group"
									@input="handleCustomerGroup"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="dropdown.customer_type"
									:options="CUSTOMER_TYPE_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Customer type"
									@input="handleCustomerType"
								/>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="lastLogin"
									label="Last login"
									placeholder="All date"
									mode="range"
									@input="handleFilterDateChange($event, 'last_login_date_from', 'last_login_date_to')"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="createdDate"
									label="Created date"
									placeholder="All period"
									mode="range"
									@input="handleFilterDateChange($event, 'created_from', 'created_to')"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="updatedDate"
									label="Updated date"
									placeholder="All period"
									mode="range"
									@input="handleFilterDateChange($event, 'updated_from', 'updated_to')"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>

		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="list(s) found"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: list.meta.lastPage,
						activePage: list.meta.currentPage,
					}"
					:fields="ABANDONED_CART_TABLE_FIELDS"
					:items="list.data"
					striped
					vertical-align="top"
					class="table-custom"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{
									$t('global.searchNotFound', {
										field: 'items',
									})
								}}
							</p>
						</div>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
		<BaseModalConfirm
			ref="modal-export"
			:is-submitting="isExporting"
			:close-after-confirm="false"
			title="Export report"
			description="The report will be sent to your email. It might be taking for 5 - 30 mins, please be patient."
			primary-button-text="Send"
			primary-button-loading-text="Sending"
			@onConfirm="handleExportReport"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseInputDate from '@/components/BaseInputDate.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import { ABANDONED_CART_TABLE_FIELDS } from '../enums/abandonedCarts';
import { CUSTOMER_TYPE_OPTIONS } from '../enums/customers';
import {
	cleanObject,
	randomSearchString,
	convertDateRangeToUTC,
	hasSearchFilter,
	cloneDeep,
	getSelectedValueDropdown,
} from '../assets/js/helpers';
import { postExportAbandonedCartReport } from '../services/api/abandonedCarts.api';

export default {
	name: 'AbandonedCart',
	components: {
		BaseInputDate,
		BaseModalConfirm,
	},
	data() {
		return {
			ABANDONED_CART_TABLE_FIELDS,
			CUSTOMER_TYPE_OPTIONS,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				last_login_date_from: this.$route.query.last_login_date_from || null,
				last_login_date_to: this.$route.query.last_login_date_to || null,
				created_from: this.$route.query.created_from || null,
				created_to: this.$route.query.created_to || null,
				updated_from: this.$route.query.updated_from || null,
				updated_to: this.$route.query.updated_to || null,
				customer_group_id: this.$route.query.customer_group_id || null,
				customer_type: this.$route.query.customer_type || null,
				r: randomSearchString(),
			},
			dropdown: {
				customer_group: null,
				customer_type: null,
			},
			isShowFilter: false,
			isLoading: false,
			isExporting: false,
		};
	},
	computed: {
		...mapGetters({
			CUSTOMER_GROUP_OPTIONS: 'customerGroups/customerGroupOptions',
		}),
		...mapState('abandonedCarts', {
			list: 'list',
		}),
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		lastLogin() {
			if (!this.queryParams.last_login_date_from || !this.queryParams.last_login_date_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.last_login_date_from),
				end: new Date(this.queryParams.last_login_date_to),
			};
		},
		createdDate() {
			if (!this.queryParams.created_from || !this.queryParams.created_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.created_from),
				end: new Date(this.queryParams.created_to),
			};
		},
		updatedDate() {
			if (!this.queryParams.updated_from || !this.queryParams.updated_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.updated_from),
				end: new Date(this.queryParams.updated_to),
			};
		},
		customerGroupsOptions() {
			return [
				{ name: 'All customer group', value: null },
				...this.CUSTOMER_GROUP_OPTIONS,
			];
		},
	},
	async created() {
		this.isLoading = true;
		this.isShowFilter = this.hasFilter;

		try {
			await Promise.all([
				this.getAbandonedCarts(this.queryParams),
				this.getCustomerGroupList({ per_page: 'all' }),
			]);

			this.dropdown.customer_group = getSelectedValueDropdown(this.queryParams.customer_group_id, this.customerGroupsOptions);
			this.dropdown.customer_type = getSelectedValueDropdown(this.queryParams.customer_type, CUSTOMER_TYPE_OPTIONS);
		} finally {
			this.isLoading = false;
		}
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			getAbandonedCarts: 'abandonedCarts/getAbandonedCarts',
			getCustomerGroupList: 'customerGroups/getCustomerGroupList',
		}),

		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleFilterDateChange(event, keyFrom, keyTo) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams[keyFrom] = start;
			this.queryParams[keyTo] = end;
			this.updateUrlParams();
		},
		handleCustomerGroup({ value }) {
			this.queryParams.customer_group_id = value;
			this.updateUrlParams();
		},
		handleCustomerType({ value }) {
			this.queryParams.customer_type = value;
			this.updateUrlParams();
		},
		async handleExportReport() {
			this.isExporting = true;
			try {
				await postExportAbandonedCartReport({
					...this.queryParams,
					report_key: 'abandoned_cart',
				});
				this.showToast({
					header: this.$t('global.successMessageTitle'),
					content: `Your request has been completed.`,
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					header: this.$t('global.errorMessageExport'),
					content: this.$t('global.errorMessage'),
					type: 'danger',
				});
			} finally {
				this.isExporting = false;
				this.$refs['modal-export'].close();
			}
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				r: null,
			};

			this.updateUrlParams();
		},
		openExportModal() {
			this.$refs['modal-export'].open();
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .form-control-date {
		input[type="input"] {
			@include placeholder-black;
		}
	}
</style>
