export const ABANDONED_CART_TABLE_FIELDS = Object.freeze([
	{ key: 'customerId', label: 'Customer ID', class: 'th-mw-150' },
	{ key: 'customerName', label: 'Customer name', class: 'th-mw-250' },
	{ key: 'customerGroup', label: 'Customer group', class: 'th-mw-100' },
	{ key: 'customerType', label: 'Customer type', class: 'th-mw-100' },
	{ key: 'product', label: 'Product', class: 'th-mw-150' },
	{ key: 'cartItems', label: 'Cart items', class: 'th-mw-150' },
	{ key: 'subtotal', label: 'Subtotal', class: 'th-mw-150' },
	{ key: 'customerLastLoginAt', label: 'Last login', class: 'th-mw-150' },
	{ key: 'createdAt', label: 'Created date', class: 'th-mw-150' },
	{ key: 'updatedAt', label: 'Updated date', class: 'th-mw-150' },
]);